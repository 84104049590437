<template>
  <!-- 佳成设备维护页-设计源于：查询详情页 -->
  <div class="queryDetails">
    <van-nav-bar
      :left-text="
        moduleno == '9970'
          ? '设备验收记录'
          : moduleno == '9971'
          ? '设备保养记录'
          : moduleno == '9968'
          ? '设备维修记录'
          : ''
      "
      class="common_query_title"
      left-arrow
      @click-left="onClickLeft"
    >
      <template #right>
        <van-icon
          name="search"
          size="18"
          color="#ffffff"
          style="margin-left: 6px"
          @click="showPicker = true"
        />
      </template>
    </van-nav-bar>
    <div class="overflow_div" :style="overflowStyle">
      <!-- 表格内容区 -->
      <div id="cq_tableContainer" class="table_content" :style="tabStyle">
        <ve-table
          :columns="thList"
          border-y
          :table-data="tableData"
          :scrollWidth="scrollWidth"
          :style="
            tableData.length == 0
              ? 'height:100px;'
              : 'height:100%;' + 'width:100%;word-break:break-word'
          "
          max-height="100%"
        />
        <div
          v-show="tableData.length == 0"
          class="empty-data"
          style="font-size:16px;text-align: center"
        >
          暂无数据
        </div>
      </div>
      <div class="has_feedback" v-if="moduleno == '9968'">
        <div
          class="has_feedback_btn1"
          v-for="(it, idx) in typelist"
          :key="idx"
          :class="{ cus: type == it }"
          @click="
            type = it
            getAllPageDatas()
          "
        >
          {{ it }}
        </div>
      </div>
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-form @submit="onSubmit">
        <van-field
          v-for="(it, idx) in form"
          :key="idx"
          :readonly="it.controltype == 'datetime'"
          :clickable="it.controltype == 'datetime'"
          v-model="it.value"
          :name="it.chnname"
          :label="it.chnname"
          :placeholder="it.chnname"
          @click="
            showCalendar = it.controltype == 'datetime'
            datetimeitem = it
          "
        />
        <van-calendar
          v-model="showCalendar"
          @confirm="onConfirm"
          :min-date="minDate"
          :max-date="maxDate"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
// 引入组件库
import { parseTime } from '@/utils'
import { VeTable } from 'vue-easytable' // VePagination, VeIcon, VeLoading, VeLocale
import { Dialog, Toast, Notify } from 'vant'
import { saveAs } from 'file-saver'
import { getReq } from '@api/wx.js'
export default {
  components: {
    VeTable
  },
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      moduleno: this.$route.query.moduleno || '9968', // 当前模块号
      v8apitoken: '7fa747b7ec0f4007a155e005a79c3701',
      V3url: './',
      langid: 0, // 当前多语言Id
      thList: [], // 表头列表
      tableData: [], // 表格数据
      scrollWidth: 60,
      overflowStyle: 'height:calc(100% - 146px)', // 包含条件和内容区域
      tabStyle: 'height:100%', // 表格高度
      times: 0,
      showPicker: false,
      showCalendar: false,
      typelist: ['未接单', '已接单', '完结'],
      type: '未接单',
      json: {},
      form: [],
      datetimeitem: {},
      quertlist: ['dev_id_gs', 'dev_name'], //可能从地址传入的字段
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31)
    }
  },
  mounted() {
    this.ini()
    // this.getAllPageDatas()
  },
  methods: {
    onSubmit() {
      this.showPicker = false
      this.form.forEach(it => {
        this.json[it.fieldname] = it.value
      })

      this.getAllPageDatas()
    },
    onConfirm(date) {
      this.datetimeitem.value = parseTime(date, '{y}/{m}/{d}')
      this.showCalendar = false
    },
    // 回退
    onClickLeft() {
      const fromPage = this.$route.query.fromPage
      if (fromPage == 'home') {
        this.$router.push('/home').catch(err => {
          console.log(err)
        })
      } else if (fromPage == 'devBY') {
        this.$router
          .push({ path: '/devBY', query: { devid: this.$route.query.devid } })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.$router.push('/query').catch(err => {
          console.log(err)
        })
      }
    },
    // 获取设备维修查询条件
    ini() {
      getReq(
        {
          autoid: 16,
          p01: this.moduleno,
          p02: this.userInfo.username
        },
        this.v8apitoken
      ).then(res => {
        res.data.forEach(it => {
          if (
            this.$route.query.fromPage == 'devBY' &&
            this.quertlist.indexOf(it.fieldname) > -1
          ) {
            it.value = this.$route.query[it.fieldname]
          } else {
            it.value = ''
          }
        })
        this.form = res.data
        this.onSubmit()
      })
    },
    // 获取设备维修查询结果
    getAllPageDatas() {
      getReq(
        {
          autoid: 17,
          p01: this.moduleno,
          p02: this.moduleno=='9968'?this.type:'',
          p03: JSON.stringify(this.json),
          p04: this.userInfo.username
        },
        this.v8apitoken
      ).then(res => {
        res.data1.forEach((it, ii) => {
          it.title = it.chnname
          it.field = it.fieldname
          // it.fixed = 'left'
          it.key = 'k' + ii
          it.width = it.selfwidth
          it.align = 'center'
          // 文本
          it.renderBodyCell = ({ row, column, rowIndex }, h) => {
            const text = row[column.field]

            const style =
              'height: 100%;  align-items: center;width: 100%;padding:10px; font-size: 14px;text-align:center;'
            return <div style={style}>{text}</div>
          }
        })
        let it = {
          fixed: 'right',
          title: '单据',
          field: 'flowmaster_autoid',
          key: 'k' + res.data1.length,
          align: 'center',
          width: 60
        }
        it.renderBodyCell = ({ row, column, rowIndex }, h) => {
          const url = require('@assets/img/ft_ico_fj.png')
          const style =
            'height: 100%;  align-items: center;width: 100%;padding:10px; font-size: 14px;text-align:center;'
          return row.flowmaster_autoid ? (
            <div
              style={style}
              on-click={() => {
                if (row.flowmaster_autoid) {
                  var url = 'wfoneinfo.html'
                  let href =
                    this.V3url +
                    '/weixinlocal/queryrpt/' +
                    url +
                    '?type=1&autoid=' +
                    row.flowmaster_autoid
                  this.$router.push({
                    path: '/jump',
                    query: { url: href, time: new Date().getTime() }
                  })
                }
              }}
            >
              <img src={url} alt="" style="width:19px;height:18px;" />
            </div>
          ) : (
            ''
          )
        }
        res.data1.push(it)

        this.thList = res.data1
        this.tableData = res.data2
      })
    }
  }
}
</script>

<style scoped lang="less">
.queryDetails {
  height: 100%;
  overflow: hidden;
  // background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-nav-bar__text {
        color: #ffffff;
        font-size: 36px;
      }
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-tag {
        margin-right: 24px;
        font-size: 24px;
        .van-icon {
          color: #ffffff;
        }
      }
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    background: #f6f6f6;
    // overflow: auto;
    height: calc(100% - 92px);

    .table_content {
      .vue-table-root {
        /deep/.ve-table {
          height: 100%;
          .ve-table-container {
            .ve-table-content-wrapper {
              .ve-table-content {
                .ve-table-header {
                  .ve-table-header-tr {
                    height: 80px;
                    .ve-table-header-th {
                      font-size: 28px;
                      font-weight: bold;
                    }
                  }
                }
                .ve-table-body {
                  .ve-table-body-tr {
                    .ve-table-body-td {
                      padding: 0 !important;
                    }
                  }
                }
                .ve-table-footer {
                  .ve-table-footer-tr {
                    height: 80px;

                    .ve-table-footer-td {
                      font-size: 28px;
                      background-color: rgb(255, 211, 184);
                    }
                  }
                }
              }
            }
          }
        }
      }
      .auto_table {
        height: 100%;
        overflow: auto;
        .table {
          width: 100%;
          border-spacing: 0px;
          tr {
            td:nth-child(1) {
              border-left: 2px solid #dcdfe6;
            }
            th,
            td {
              padding: 6px 10px;
              text-align: center;
              border-bottom: 2px solid #dcdfe6;
              border-right: 2px solid #dcdfe6;
              .comm_box {
                .boolean_box {
                  .van-checkbox {
                    justify-content: center;
                    /deep/.van-checkbox__icon--checked {
                      .van-icon {
                        color: #fff !important;
                        background-color: #1989fa !important;
                        border-color: #1989fa !important;
                      }
                    }
                    /deep/.van-checkbox__icon--disabled .van-icon {
                      background-color: #fff;
                    }
                  }
                }
              }
            }
          }
          .top {
            position: sticky;
            top: 0;
            font-size: 28px;
            background: #e9f3fd;
            height: 64px;
            th:nth-child(1) {
              border-left: 2px solid #dcdfe6;
            }
          }
          .cont {
            height: 64px;
            font-size: 24px;
          }
          .cont:nth-child(2n-1) {
            background: #f5f5f5;
          }
        }
      }
    }
  }
  .has_feedback {
    position: relative;
    z-index: 100;
    height: 132px;
    width: 100%;
    padding: 0 20px;
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
    .onlyOneBtn {
      width: 90% !important;
    }
    .has_feedback_btn1,
    .has_feedback_btn2 {
      height: 90px;
      text-align: center;
      line-height: 90px;
      color: #fff;
      font-size: 32px;
      border-radius: 12px;
      flex: auto;
    }
    .has_feedback_btn1 {
      // width: 40%;
      // width: 30%;
      // background: rgb(255, 174, 201);
      background: #2b8df0;
    }
    .cus {
      // width: 40%;
      // width: 30%;
      background: rgb(252, 175, 61);
    }
  }
}
</style>
