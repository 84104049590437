var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"queryDetails"},[_c('van-nav-bar',{staticClass:"common_query_title",attrs:{"left-text":_vm.moduleno == '9970'
        ? '设备验收记录'
        : _vm.moduleno == '9971'
        ? '设备保养记录'
        : _vm.moduleno == '9968'
        ? '设备维修记录'
        : '',"left-arrow":""},on:{"click-left":_vm.onClickLeft},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('van-icon',{staticStyle:{"margin-left":"6px"},attrs:{"name":"search","size":"18","color":"#ffffff"},on:{"click":function($event){_vm.showPicker = true}}})]},proxy:true}])}),_c('div',{staticClass:"overflow_div",style:(_vm.overflowStyle)},[_c('div',{staticClass:"table_content",style:(_vm.tabStyle),attrs:{"id":"cq_tableContainer"}},[_c('ve-table',{style:(_vm.tableData.length == 0
            ? 'height:100px;'
            : 'height:100%;' + 'width:100%;word-break:break-word'),attrs:{"columns":_vm.thList,"border-y":"","table-data":_vm.tableData,"scrollWidth":_vm.scrollWidth,"max-height":"100%"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableData.length == 0),expression:"tableData.length == 0"}],staticClass:"empty-data",staticStyle:{"font-size":"16px","text-align":"center"}},[_vm._v(" 暂无数据 ")])],1),(_vm.moduleno == '9968')?_c('div',{staticClass:"has_feedback"},_vm._l((_vm.typelist),function(it,idx){return _c('div',{key:idx,staticClass:"has_feedback_btn1",class:{ cus: _vm.type == it },on:{"click":function($event){_vm.type = it
          _vm.getAllPageDatas()}}},[_vm._v(" "+_vm._s(it)+" ")])}),0):_vm._e()]),_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_vm._l((_vm.form),function(it,idx){return _c('van-field',{key:idx,attrs:{"readonly":it.controltype == 'datetime',"clickable":it.controltype == 'datetime',"name":it.chnname,"label":it.chnname,"placeholder":it.chnname},on:{"click":function($event){_vm.showCalendar = it.controltype == 'datetime'
          _vm.datetimeitem = it}},model:{value:(it.value),callback:function ($$v) {_vm.$set(it, "value", $$v)},expression:"it.value"}})}),_c('van-calendar',{attrs:{"min-date":_vm.minDate,"max-date":_vm.maxDate},on:{"confirm":_vm.onConfirm},model:{value:(_vm.showCalendar),callback:function ($$v) {_vm.showCalendar=$$v},expression:"showCalendar"}}),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v("提交")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }